@import "../node_modules/modern-normalize/modern-normalize.css";

@font-face {
	font-family: "Inter var";
	font-weight: 100 900;
	font-display: optional;
	font-style: italic;
	font-named-instance: "Italic";
	src: url("/fonts/Inter-italic.var.woff2") format("woff2");
}

@font-face {
	font-family: "Inter var";
	font-weight: 100 900;
	font-display: optional;
	font-style: normal;
	font-named-instance: "Regular";
	src: url("/fonts/Inter-roman.var.woff2") format("woff2");
}

@font-face {
	font-family: "InterDisplay var";
	font-weight: 100 900;
	font-display: optional;
	font-style: italic;
	font-named-instance: "Italic";
	src: url("/fonts/InterDisplay-italic.var.woff2") format("woff2");
}

@font-face {
	font-family: "InterDisplay var";
	font-weight: 100 900;
	font-display: optional;
	font-style: normal;
	font-named-instance: "Regular";
	src: url("/fonts/InterDisplay-roman.var.woff2") format("woff2");
}

[data-theme="light"] {
	--bg-test: #ffffff;
	--font-color: #000000;
}

[data-theme="dark"] {
	--bg-test: #000000;
	--font-color: #ffffff;
}

:root {
	/*
	START REFACTORING
	*/
	/* --size: font-size */
	--size-xsmall: 12px;
	--size-small: 14px;
	--size-medium: 16px;
	--size-large: 18px;
	--size-xlarge: 20px;
	--size-xlarge2: 24px;
	--size-xlarge3: 30px;
	--size-xlarge4: 36px;
	--size-xlarge5: 48px;
	--size-xlarge6: 60px;
	--size-xlarge7: 72px;
	--size-xlarge8: 96px;
	/* --tracking: letter-spacing */
	--tracking-xsmall: 0em;
	--tracking-small: -0.006em;
	--tracking-medium: -0.011em;
	--tracking-large: -0.014em;
	--tracking-xlarge: -0.017em;
	--tracking-xlarge2: -0.019em;
	--tracking-xlarge3: -0.021em;
	--tracking-xlarge4: -0.022em;
	--tracking-xlarge5: -0.022em;
	--tracking-xlarge6: -0.022em;
	--tracking-xlarge7: -0.022em;
	--tracking-xlarge8: -0.022em;
	/* --leading: line-height */
	--leading-xsmall: 17px;
	--leading-small: 20px;
	--leading-medium: 22px;
	--leading-large: 25px;
	--leading-xlarge: 28px;
	--leading-xlarge2: 34px;
	--leading-xlarge3: 42px;
	--leading-xlarge4: 50px;
	--leading-xlarge5: 67px;
	--leading-xlarge6: 84px;
	--leading-xlarge7: 86px;
	--leading-xlarge8: 115px;
	/* --weight: font-weight */
	--weight-normal: 400;
	--weight-heavy: 600;
	/* --space: margin/padding/row-gap/column-gap */
	--space-xsmall: 4px;
	--space-small: 8px;
	--space-medium: 12px;
	--space-large: 16px;
	--space-xlarge: 24px;
	--space-xlarge1: 32px;
	--space-xlarge2: 48px;
	--space-xlarge3: 64px;
	--space-xlarge4: 96px;
	--space-xlarge5: 128px;
	--space-xlarge6: 192px;
	--space-xlarge7: 256px;
	--space-xlarge8: 384px;
	--space-xlarge9: 512px;
	--space-xlarge10: 640px;
	--space-xlarge11: 768px;
	/*
	END REFACTORING
	*/
	--color-background: var(--bg-test);
	/* --font: font-family */
	--font-sans: system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
		sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
	--font-display: system-ui, -apple-system, "Segoe UI", Roboto, Helvetica,
		Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
	/* --size: font-size */
	--size-hero: var(--size-xlarge8);
	--size-hero-small: var(--size-xlarge7);
	--size-product: var(--size-xlarge5);
	--size-product-small: var(--size-xlarge4);
	--size-title: 38px;
	--size-title-small: 24px;
	--size-caption: 18px;
	--size-body: 18px;
	--size-body-small: 18px;
	--size-button: 14px;
	--size-logo: 16px;
	--size-navigation: 15px;
	--size-subitem: 14px;
	--size-subitem-title: 14px;
	--size-copyright: 12px;
	--size-label: 16px;
	/* --tracking: letter-spacing */
	--tracking-hero: var(--tracking-xlarge8);
	--tracking-hero-small: var(--tracking-xlarge7);
	--tracking-product: var(--tracking-xlarge5);
	--tracking-product-small: var(--tracking-xlarge4);
	--tracking-title: -0.022em;
	--tracking-title-small: -0.019em;
	--tracking-caption: -0.014em;
	--tracking-body: -0.014em;
	--tracking-body-small: -0.014em;
	--tracking-button: -0.006em;
	--tracking-logo: -0.006em;
	--tracking-navigation: -0.009em;
	--tracking-subitem: -0.006em;
	--tracking-subitem-title: -0.006em;
	--tracking-copyright: 0em;
	--tracking-label: -0.006em;
	/* --leading: line-height */
	--leading-hero: var(--leading-xlarge8);
	--leading-hero-small: var(--leading-xlarge7);
	--leading-product: var(--leading-xlarge5);
	--leading-product-small: var(--leading-xlarge4);
	--leading-title: 53px;
	--leading-title-small: 34px;
	--leading-caption: 25px;
	--leading-body: 25px;
	--leading-body-small: 25px;
	--leading-button: 20px;
	--leading-logo: 20px;
	--leading-navigation: 21px;
	--leading-subitem: 18px;
	--leading-subitem-title: 18px;
	--leading-copyright: 17px;
	--leading-label: 20px;
	/* --weight: font-weight */
	--weight-hero: 800;
	--weight-hero-small: 800;
	--weight-product: 700;
	--weight-product-small: 700;
	--weight-title: 700;
	--weight-title-small: 700;
	--weight-caption: 700;
	--weight-body: 500;
	--weight-body-small: 500;
	--weight-button: 600;
	--weight-logo: 700;
	--weight-logo-var: 400;
	--weight-navigation: 600;
	--weight-subitem: 400;
	--weight-subitem-title: 500;
	--weight-copyright: 400;
	--weight-label: 400;
}

@supports (font-variation-settings: normal) {
	:root {
		--font-sans: "Inter var", system-ui, -apple-system, "Segoe UI", Roboto,
			Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
		--font-display: "InterDisplay var", system-ui, -apple-system, "Segoe UI",
			Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
			"Segoe UI Emoji";
	}
}

html,
body {
	background-color: var(--color-background);
	font-family: var(--font-sans);
	font-size: var(--text-sm);
	color: var(--font-color);
	line-height: var(--leading-sm);
	font-weight: var(--font-normal);
	letter-spacing: var(--tracking-normal);
	-webkit-font-smoothing: antialiased;
	width: 100vw;
	height: 100vh;
	box-sizing: border-box;
	margin: 0px;
}

h1 {
	margin: 0px;
}

h2 {
	margin: 0px;
}

ul {
	margin: 0px;
	padding: 0px;
}

li {
	list-style-type: none;
}

a {
	text-decoration: none;
	text-align: left;
}

p {
	margin: 0px;
}

button {
	border: 0;
	background-color: transparent;
	padding: 0;
}
